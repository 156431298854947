import React, { useState } from "react";
import { TextInput } from "../../library";
import { fetch, post } from "../../functions";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

export default function DataExportIndex() {
    const initialState = {
        county: null,
        min_acre: "",
        max_acre: "",
        max_sq_ft: "",
    };
    const [exportParams, setExportParams] = useState(initialState);
    const [count, setCount] = useState(0);
    const [regions, setRegions] = useState([]);

    const formInvalid = () => {
        return exportParams.county === null;
    };

    const removeUnsetParams = (params) => {
        return Object.entries(params).reduce((acc, [k, v]) => {
            if (v !== "") {
                acc[k] = v;
            }
            return acc;
        }, {});
    };

    const applyParams = async () => {
        if (formInvalid()) {
            return;
        }
        const resp = await post(
            "/api/property/data_export_count/",
            removeUnsetParams(exportParams),
        );
        setCount(resp.count);
    };

    const downloadExport = async () => {
        if (formInvalid()) {
            return;
        }
        const resp = await post(
            "/api/property/data_export/",
            removeUnsetParams(exportParams),
        );
        const csvFile = new Blob([resp], { type: "text/csv" });
        const url = window.URL.createObjectURL(csvFile);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = "data-export.csv";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const resetFilters = () => {
        setExportParams({ ...initialState });
        setCount(0);
        setRegions([]);
    };

    const getRegions = (value) => {
        fetch("/api/property/fips_lookup/?name=" + value).then((regions) => {
            setRegions(regions.results);
        });
    };

    let debounceTimeout = undefined;
    const onInputChange = (event, value, reason) => {
        clearTimeout(debounceTimeout);
        debounceTimeout = setTimeout(() => {
            if (value) {
                getRegions(value);
            } else {
                setRegions([]);
            }
        }, 250);
    };

    const onFormChange = (e) => {
        const newParams = {
            ...exportParams,
            [e.target.name]: e.target.value,
        };
        setExportParams(newParams);
        setCount(0);
    };

    return (
        <div className="card">
            <div className="card-header pb-0 d-sm-flex justify-content-between">
                <h5>Export Data</h5>
            </div>
            <div className="card-body">
                <div className="mb-4 w-lg-50 mx-auto">
                    <form>
                        <Autocomplete
                            id="region-autocomplete"
                            options={regions}
                            filterOptions={(x) => x}
                            autoComplete
                            onInputChange={onInputChange}
                            onChange={(e, newValue) => {
                                setExportParams({
                                    ...exportParams,
                                    county: newValue?.id || "",
                                });
                                setCount(0);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    error={formInvalid()}
                                    className="form-control"
                                    name="County"
                                    placeholder="County"
                                />
                            )}
                        />
                        <TextInput
                            key="min_acre"
                            label="Minimum Acreage"
                            name="min_acre"
                            handle_change={onFormChange}
                            value={exportParams.min_acre}
                        />
                        <TextInput
                            key="max_acre"
                            label="Maximum Acreage"
                            name="max_acre"
                            handle_change={onFormChange}
                            value={exportParams.max_acre}
                        />
                        <TextInput
                            key="max_sq_ft"
                            label="Maximum Building SqFt"
                            name="max_sq_ft"
                            handle_change={onFormChange}
                            value={exportParams.max_sq_ft}
                        />
                    </form>
                </div>
                <div className="d-flex justify-content-center gap-2">
                    <button
                        type="button"
                        className="btn bg-gradient-primary"
                        onClick={applyParams}
                    >
                        Apply
                    </button>
                    <button
                        type="button"
                        disabled={count < 1}
                        className="btn bg-gradient-secondary"
                        onClick={downloadExport}
                    >
                        Export CSV
                    </button>
                    <button type="button" className="btn" onClick={resetFilters}>
                        Clear Filters
                    </button>
                </div>
                <h5 className="d-flex justify-content-center gap-2">
                    Matching Properties: {count}
                </h5>
            </div>
        </div>
    );
}
