import { MAPBOX_TOKEN } from "settings";

const sessionID = generateSessionID();

// Ref: https://docs.mapbox.com/api/search/search-box/
export async function fetchMapboxSuggestions(query, options) {
    const url = "https://api.mapbox.com/search/searchbox/v1/suggest";
    const params = {
        q: query,
        language: "en",
        country: "us",
        session_token: sessionID,
        access_token: MAPBOX_TOKEN,
        ...options,
    };

    const queryString = new URLSearchParams(params).toString();
    const fullUrl = `${url}?${queryString}`;

    const response = await fetch(fullUrl);
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
}

// Ref: https://docs.mapbox.com/api/search/search-box/#retrieve-a-suggested-feature
export async function fetchMapboxFeature(id) {
    const url = `https://api.mapbox.com/search/searchbox/v1/retrieve/${id}`;
    const params = {
        session_token: sessionID,
        access_token: MAPBOX_TOKEN,
    };

    const queryString = new URLSearchParams(params).toString();
    const fullUrl = `${url}?${queryString}`;

    const response = await fetch(fullUrl);
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
}

function generateSessionID() {
    if (crypto && typeof crypto.randomUUID === "function") {
        return crypto.randomUUID();
    }
    return `${Math.random()}`;
}
