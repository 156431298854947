import React, { Component } from "react";

import { toast } from "react-toastify";

import { Button, DeleteButton } from "library";
import { ajax_wrapper, formatDate } from "functions";
import { AGGrid } from "components";

class CompDownload extends Component {
    constructor(props) {
        super(props);

        this.state = {
            downloading: false,
        };

        this.run_download = this.run_download.bind(this);
    }

    run_download() {
        this.setState(
            { downloading: false },
            function () {
                ajax_wrapper(
                    "POST",
                    `/analytics/download_comps/${this.props.data["id"]}/`,
                    {},
                    function (value) {
                        toast.success("Download Started!");
                        this.setState({ downloading: false });
                        this.props.callback();
                    }.bind(this),
                );
            }.bind(this),
        );
    }

    render() {
        let download_button = null;
        if (this.props.data.status == "Complete") {
            download_button = (
                <Button
                    style={{ marginLeft: "15px" }}
                    onClick={this.run_download}
                    type="gradient-primary"
                    disabled={this.state.downloading}
                >
                    Download
                </Button>
            );
        }

        let last_download = <div>{download_button}</div>;
        if (this.props.data["comp_download_date"]) {
            last_download = (
                <div>
                    {`Last Downloaded: ${this.props.data["comp_download_date"]}`}
                    <div>{download_button}</div>
                </div>
            );
        }

        return <div>{last_download}</div>;
    }
}

export default class Scraping extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            scraping_jobs: [],
            metrics_job: null,

            running_metrics: false,
        };

        this.get_jobs = this.get_jobs.bind(this);
        this.start_scraping = this.start_scraping.bind(this);
        this.run_metrics = this.run_metrics.bind(this);
    }

    componentDidMount() {
        this.get_jobs();
    }

    get_jobs() {
        this.setState(
            { loaded: false },
            function () {
                ajax_wrapper("GET", "/analytics/scraping_status/", {}, (value) =>
                    this.setState({
                        scraping_jobs: value["scraping"],
                        metrics_job: value["metrics"],
                        loaded: true,
                    }),
                );
            }.bind(this),
        );
    }

    start_scraping() {
        this.setState(
            {
                running_scraping: true,
            },
            () =>
                ajax_wrapper(
                    "POST",
                    "/analytics/scraping_request/",
                    {},
                    function (value) {
                        toast.success("Jobs Started!");
                        this.get_jobs();
                        this.setState({
                            running_scraping: false,
                        });
                    }.bind(this),
                ),
        );
    }

    run_metrics() {
        this.setState(
            {
                running_metrics: true,
            },
            ajax_wrapper(
                "POST",
                "/analytics/run_analytics/",
                {},
                function (value) {
                    toast.success("Metrics Started!");
                    this.get_jobs();
                    this.setState({
                        running_metrics: false,
                    });
                }.bind(this),
            ),
        );
    }

    render() {
        let job_rows = [];
        const scraping_jobs = this.state.scraping_jobs || [];
        for (let item of scraping_jobs) {
            job_rows.push({
                id: item.id,
                status: item.status,
                created_at: item.created_at,
                updated_at: item.updated_at,
                comp_download_date: item.comp_download_date,
                requester: item.requester.email,
            });
        }

        let columns = [
            { field: "status", filter: true },
            {
                field: "created_at",
                headerName: "Created At",
                valueFormatter: ({ value }) => formatDate(value),
                filter: true,
                cellDataType: "dateString",
            },
            {
                field: "updated_at",
                headerName: "Updated At",
                valueFormatter: ({ value }) => formatDate(value),
                filter: true,
                cellDataType: "dateString",
            },
            {
                field: "comp_download_date",
                cellRenderer: CompDownload,
                cellRendererParams: {
                    callback: this.get_jobs,
                },
                autoHeight: true,
            },
            {
                field: "requester",
                filter: true,
            },
            {
                field: "id",
                cellRenderer: DeleteButton,
                cellRendererParams: {
                    url: `/analytics/stop_scraping/`,
                    callback: this.get_jobs,
                },
            },
        ];

        if (!this.state.loaded) {
            return "Loading...";
        }

        return (
            <>
                <div className="d-flex">
                    <h5 className="flex-fill">Scraping</h5>
                    <Button
                        type="gradient-primary"
                        onClick={this.start_scraping}
                        disabled={this.state.running_scraping}
                    >
                        Create New Scraping Job
                    </Button>
                </div>
                <AGGrid fill_table={true} rows={job_rows} columns={columns} />
            </>
        );
    }
}
