import React, { useState, cloneElement } from "react";
import { useControl } from "react-map-gl";
import { createPortal } from "react-dom";

class MapControl {
    constructor(id, className, redraw) {
        this.id = id;
        this.className = className;
        this.redraw = redraw;
    }

    onAdd(map) {
        this._map = map;
        this.el = document.createElement("div");
        if (this.id) {
            this.el.id = this.id;
        }
        this.el.className = `mapboxgl-ctrl mapboxgl-ctrl-group mapboxgl-ctrl-custom ${this.className || ""}`;
        this.redraw();
        return this.el;
    }

    onRemove() {
        this.el.remove();
        this._map = null;
    }

    getElement() {
        return this.el;
    }
}

// ReactControl is an adapter which allows us to create map controls with
// arbitrary React content.
//
// This component uses the useControl hook to create a container div, which
// gets added to the map, then renders its child components into the container
// using a portal.
//
// Adapted from https://github.com/visgl/react-map-gl/blob/7.1-release/examples/custom-overlay/src/custom-overlay.tsx
export default function ReactMapControl({ id, children, className, position }) {
    const [, setVersion] = useState(0);

    const ctrl = useControl(
        () => {
            const redraw = () => setVersion((v) => v + 1);
            return new MapControl(id, className, redraw);
        },
        { position },
    );

    const el = ctrl.getElement();

    return el && createPortal(cloneElement(children), el);
}
