import React, { useState, useCallback, useContext } from "react";

import Dropdown from "react-bootstrap/Dropdown";

import { useMap } from "react-map-gl";

import { fetch, fetchMapboxSuggestions, fetchMapboxFeature } from "functions";

import MapContext from "../context";
import MapIconButton from "./map_button";
import ReactMapControl from "./map_control";
import LITypeahead from "./typeahead";

// Search options
const SEARCH_GPS = "GPS";
const SEARCH_PARCEL = "Parcel #";
const SEARCH_COUNTY = "County";
const SEARCH_ZIP = "ZIP Code";
const SEARCH_ADDRESS = "Address";

// Mapbox filters
const COUNTY_FILTER = [
    "all",
    ["==", ["get", "level"], 2],
    ["==", ["get", "iso_a2"], "US"],
];
const ZIP_FILTER = [
    "all",
    ["==", ["get", "level"], 1],
    ["==", ["get", "iso_a2"], "US"],
];

// Map featureType to Mapbox layers
const FEATURE_TYPE_MAPPING = {
    district: {
        identifyLayer: "county_identify",
        filter: COUNTY_FILTER,
        idField: ["id"],
    },
    postcode: { identifyLayer: "zip_identify", filter: ZIP_FILTER, idField: ["id"] },
    // Parcel layer must use feature-state ID (properties) as a work workaround
    // because layer filters don't work with "promoteId" (Mapbox bug).
    // The "address" feature type mapping is used to locate parcel features for
    // both the Mapbox Address search and Parcel # search.
    address: { identifyLayer: "parcel_identify", idField: ["get", "id"] },
};

export function SearchControl({ open, setOpen }) {
    return (
        <ReactMapControl position="top-right">
            <MapIconButton
                icon="fa-solid fa-magnifying-glass"
                title="Parcel Search"
                enableTooltip={!open}
                onClick={() => setOpen(!open)}
            />
        </ReactMapControl>
    );
}

export function SearchBox({ setActiveParcelID }) {
    const { current: map } = useMap();
    const { setMapFilter } = useContext(MapContext);
    const [searchMode, setSearchMode] = useState(SEARCH_COUNTY);
    const [activeCountyID, setActiveCountyID] = useState();

    // Locate parcel on map and mark it active
    const highlightParcel = useCallback(
        async (point) => {
            setMapFilter();

            const result = await findParcelOnMap(map, point);
            console.log("Map feature", result);

            if (result && result.mapFeature) {
                const { identifyLayer, idField, mapFeature } = result;
                const parcelID = mapFeature.id;

                const filter = ["==", idField, parcelID];
                const inverseFilter = ["!", filter];
                setMapFilter({ identifyLayer, filter, inverseFilter });
                setActiveParcelID(parcelID);
            }
        },
        [map, setMapFilter, setActiveParcelID],
    );

    // Locate feature on map and highlight it by applying shadow filter
    const highlightFeature = useCallback(
        async (feature) => {
            // Reset filters
            setMapFilter();

            const result = await findFeatureOnMap(map, feature);
            console.log("Map feature", result);

            if (result && result.mapFeature) {
                const { identifyLayer, filter, idField, mapFeature } = result;

                // Filter layer by feature ID
                let newFilter = ["==", idField, mapFeature.id];
                let inverseFilter = ["!", newFilter];

                // Include County/Zip layer filter
                if (filter) {
                    newFilter = ["all", filter, newFilter];
                    inverseFilter = ["all", filter, inverseFilter];
                }

                // Apply map filter for shadow layer
                setMapFilter({ identifyLayer, filter: newFilter, inverseFilter });

                // Mark county as "active" for Parcel # search
                const featureType = feature?.properties?.feature_type;
                if (featureType === "district") {
                    setActiveCountyID(mapFeature.properties.code);
                }
            }
        },
        [map, setMapFilter],
    );

    // Handle GPS "search result" clicked
    const onPointSelected = (point) => {
        setMapFilter();
        map.easeTo({ center: point, zoom: 16, duration: 0 });
    };

    // Handle Parcel # search result clicked
    const onParcelSelected = useCallback(
        ({ point }) => {
            highlightParcel(point);
        },
        [highlightParcel],
    );

    // Handle Mapbox search result clicked
    const onFeatureSelected = useCallback(
        async (feature) => {
            const featureType = feature?.properties?.feature_type;
            if (featureType === "district") {
                // Handle county features
                highlightFeature(feature);
            } else if (featureType === "postcode") {
                // Handle ZIP features
                highlightFeature(feature);
            } else if (featureType === "address") {
                // Handle address features (assume parcel exists at address)
                const { longitude, latitude } = feature.properties.coordinates;
                const point = [longitude, latitude];
                highlightParcel(point);
            } else {
                console.log("Unknown feature type", featureType);
            }
        },
        [highlightFeature, highlightParcel],
    );

    const searchField =
        searchMode === SEARCH_GPS ? (
            <LngLatSearch onPointSelected={onPointSelected} />
        ) : searchMode === SEARCH_PARCEL ? (
            <>
                <MapboxSearch
                    key="district"
                    featureType="district"
                    placeholder="Enter county name"
                    onFeatureSelected={onFeatureSelected}
                    className="border-radius-0"
                />
                <ParcelSearch
                    countyID={activeCountyID}
                    onParcelSelected={onParcelSelected}
                />
            </>
        ) : searchMode === SEARCH_COUNTY ? (
            <MapboxSearch
                key="district"
                featureType="district"
                placeholder="Enter county name"
                onFeatureSelected={onFeatureSelected}
            />
        ) : searchMode === SEARCH_ZIP ? (
            <MapboxSearch
                key="postcode"
                featureType="postcode"
                placeholder="Enter ZIP code"
                onFeatureSelected={onFeatureSelected}
            />
        ) : searchMode === SEARCH_ADDRESS ? (
            <MapboxSearch
                key="address"
                featureType="address"
                placeholder="Enter address"
                onFeatureSelected={onFeatureSelected}
            />
        ) : null;

    return (
        <div id="search-control">
            <SearchDropdown
                selectedValue={searchMode}
                onChange={(value) => setSearchMode(value)}
            />
            {searchField}
        </div>
    );
}

// Find Mapbox feature on map using administrative and parcel layers.
async function findFeatureOnMap(map, feature) {
    const { longitude, latitude } = feature.properties.coordinates;

    // County/Zip features return bbox. Address features return point.
    if (feature.properties.bbox) {
        map.fitBounds(feature.properties.bbox, {
            padding: 40,
            // duration has a nonzero value to give tiles time to load before
            // calling queryRenderedFeatures. Otherwise, the projected point
            // may reference off-screen pixel values.
            duration: 250,
        });
    } else {
        map.easeTo({ center: [longitude, latitude], zoom: 16, duration: 0 });
    }

    // Wait for map animation to stop, and for tiles to load, in order for
    // queryRenderedFeatures to work.
    await asyncMapEvent(map, "idle");

    const mapping = FEATURE_TYPE_MAPPING[feature.properties.feature_type];
    if (!mapping) {
        console.log("feature type not supported", feature);
        return;
    }

    const { identifyLayer, filter, idField } = mapping;
    const point = map.project([longitude, latitude]);
    const features = map.queryRenderedFeatures(point, { layers: [identifyLayer] });

    const mapFeature = features && features[0];

    return { identifyLayer, filter, idField, mapFeature };
}

// Find parcel on map from its coordinates.
async function findParcelOnMap(map, point) {
    map.easeTo({ center: point, zoom: 16, duration: 0 });

    // Wait for map animation to stop, and for tiles to load, in order for
    // queryRenderedFeatures to work.
    await asyncMapEvent(map, "idle");

    const { identifyLayer, idField } = FEATURE_TYPE_MAPPING.address;
    const screenPoint = map.project(point);
    const features = map.queryRenderedFeatures(screenPoint, {
        layers: [identifyLayer],
    });

    const mapFeature = features && features[0];

    return { identifyLayer, idField, mapFeature };
}

function LngLatSearch({ onPointSelected }) {
    const [options, setOptions] = useState();
    const [isValid, setIsValid] = useState(true);

    const searchGPS = (query) => {
        const lngLat = parseLngLat(query);
        if (!lngLat) {
            setIsValid(false);
            setOptions([]);
            return;
        }
        setIsValid(true);
        setOptions([
            {
                point: lngLat,
                label: `${lngLat[0]}, ${lngLat[1]}`,
            },
        ]);
    };

    const onSearchResultClicked = (selection) => {
        const option = selection[0];
        if (!option) {
            return;
        }
        onPointSelected(option.point);
    };

    return (
        <LITypeahead
            placeholder="Enter GPS (longitude, latitude)"
            emptyLabel="Type valid GPS to continue"
            promptText="Continue typing GPS..."
            options={options}
            onSearch={searchGPS}
            onChange={onSearchResultClicked}
            isInvalid={!isValid}
            minLength={1}
        />
    );
}

function parseLngLat(str) {
    const regex = /[,\s/]+/; // Regular expression to match comma, space, or forward slash
    const parts = str.split(regex); // Split the string based on the regex

    // Check that there are exactly two parts and neither part is an empty string
    if (parts.length !== 2 || parts[0].trim() === "" || parts[1].trim() === "") {
        return null;
    }

    // Convert parts to numbers
    const lng = Number(parts[0]);
    const lat = Number(parts[1]);

    // Validate that latitude is between -90 and 90
    if (isNaN(lat) || lat < -90 || lat > 90) {
        return null;
    }

    // Validate that longitude is between -180 and 180
    if (isNaN(lng) || lng < -180 || lng > 180) {
        return null;
    }

    return [lng, lat];
}

function ParcelSearch({ countyID, onParcelSelected }) {
    const [options, setOptions] = useState();
    const [loading, setLoading] = useState(false);

    const searchParcel = async (query) => {
        setLoading(true);
        try {
            const pagedResults = await fetch(
                `/api/property/parcels/?county=${countyID}&property_id=${query}&page_size=5`,
            );
            const options = pagedResults.results.map(({ PropertyID, point }) => ({
                label: `${PropertyID}`,
                point,
            }));
            setOptions(options);
        } catch (xhr) {
            console.log(xhr);
        }
        setLoading(false);
    };

    const onSearchResultClicked = (selection) => {
        const option = selection[0];
        if (!option) {
            return;
        }
        onParcelSelected(option);
    };

    return (
        <LITypeahead
            placeholder="Enter Parcel #"
            isLoading={loading}
            options={options}
            onSearch={searchParcel}
            onChange={onSearchResultClicked}
            disabled={!countyID}
        />
    );
}

function MapboxSearch({ featureType, placeholder, onFeatureSelected, ...props }) {
    const { current: map } = useMap();
    const [options, setOptions] = useState();
    const [loading, setLoading] = useState(false);

    const onSearch = async (query) => {
        setLoading(true);
        const suggestions = await searchMapbox(query, featureType);
        const options = suggestions?.map((item) => ({
            id: item.mapbox_id,
            label: item.name,
            subLabel: item.place_formatted,
        }));
        setOptions(options);
        setLoading(false);
    };

    const onSearchResultClicked = useCallback(
        async (selection) => {
            const option = selection[0];
            if (!option) {
                return;
            }
            const featureCollection = await fetchMapboxFeature(option.id);
            const feature = featureCollection.features[0];
            console.log("Mapbox search feature", feature);
            onFeatureSelected(feature);
        },
        [onFeatureSelected],
    );

    return (
        <LITypeahead
            key={featureType}
            placeholder={placeholder}
            isLoading={loading}
            options={options}
            onSearch={onSearch}
            onChange={onSearchResultClicked}
            {...props}
        />
    );
}

async function searchMapbox(query, featureType) {
    try {
        const data = await fetchMapboxSuggestions(query, { types: featureType });
        return data?.suggestions || [];
    } catch (xhr) {
        console.log(xhr);
    }
}

function SearchDropdown({ selectedValue, onChange }) {
    const options = [
        SEARCH_GPS,
        SEARCH_COUNTY,
        SEARCH_ZIP,
        SEARCH_ADDRESS,
        SEARCH_PARCEL,
    ];
    return (
        <Dropdown>
            <Dropdown.Toggle
                variant="secondary-outline"
                id="search-mode-dropdown-button"
                className="mb-0"
            >
                {selectedValue}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {options.map((value, i) => (
                    <Dropdown.Item key={i} onClick={() => onChange(value)}>
                        {value}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
}

// Wrap map event handler in promise to use with async/await.
function asyncMapEvent(map, eventName) {
    return new Promise((resolve) => {
        map.once(eventName, () => resolve());
    });
}
