import { ExternalLayout } from "components";
import {
    Landing,
    Login,
    Logout,
    Register,
    RegisterOnboarding,
    RegisterSubscription,
    ResetPasswordRequest,
    ResetPasswordConfirm,
} from "pages";

const routes = [
    {
        path: "/",
        element: <ExternalLayout />,
        children: [
            {
                index: true,
                path: "",
                element: <Landing />,
            },
            {
                path: "login",
                element: <Login />,
            },
            {
                path: "register",
                // Component must be title case!
                Component: () => {
                    window.location.href = "https://landinsights.co/apply";
                    return null;
                },
            },
            {
                path: "create-account",
                children: [
                    { index: true, element: <Register /> },
                    {
                        path: "onboarding",
                        element: <RegisterOnboarding />,
                    },
                    {
                        path: "subscription",
                        element: <RegisterSubscription />,
                    },
                ],
            },
            {
                path: "logout",
                element: <Logout />,
            },
            {
                path: "reset-password/confirm/:userID/:token",
                element: <ResetPasswordConfirm />,
            },
            {
                path: "reset-password",
                element: <ResetPasswordRequest />,
            },
        ],
    },
];

export default routes;
