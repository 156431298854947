import React, { useRef, useEffect } from "react";

import { AsyncTypeahead, Highlighter } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";

// LITypeahead wraps AsyncTypeahead to provide sensible defaults.
export default function LITypeahead({ inputProps, ...props }) {
    const ref = useRef();

    // Bypass client-side filtering by returning `true`. Results are already
    // filtered by the search endpoint, so no need to do it again.
    const filterBy = () => true;

    // Change input type to "search" to disable autocomplete in Chrome
    inputProps = { type: "search", autoComplete: "off", ...inputProps };

    // Focus input field on mount
    useEffect(() => {
        ref.current?.focus();
    }, [ref]);

    return (
        <AsyncTypeahead
            ref={ref}
            id="typeahead"
            labelKey="label"
            minLength={3}
            filterBy={filterBy}
            emptyLabel="No matches found"
            renderMenuItemChildren={renderMenuItemChildren}
            useCache={false}
            highlightOnlyResult
            inputProps={inputProps}
            {...props}
        />
    );
}

function renderMenuItemChildren(option, { text }) {
    return (
        <>
            <Highlighter search={text}>{option.label}</Highlighter>
            {option.subLabel && (
                <div>
                    <small>{option.subLabel}</small>
                </div>
            )}
        </>
    );
}
