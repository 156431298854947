import React, { Component } from "react";

import { Card } from "library";
import { withNavigate } from "hooks";

class Landing extends Component {
    componentDidMount() {
        // Can't call hooks immediately in componentDidMount.
        setTimeout(() => this.props.navigate("/home"));
    }

    render() {
        return (
            <Card>
                <div className="card-header text-center pt-4"></div>
                <div className="card-body">
                    You are being redirected, please wait...
                </div>
            </Card>
        );
    }
}

export default withNavigate(Landing);
