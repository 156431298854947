const TIME_SCALES = ["1 Months", "3 Months", "6 Months", "1 Year"];

const ACRE_RANGES = {
    "0.2 acre-100 acre": "0.2 to 100 Acres",
    "1 acre-2 acre": "1 to 2 Acres",
    "2 acre-5 acre": "2 to 5 Acres",
    "5 acre-10 acre": "5 to 10 Acres",
    "5 acre-100 acre": "5 to 100 Acres",
    "5 acre-10000 acre": "5 to 10000 Acres",
    "0-9.5k sqft": "0 to 9.5k sqft",
    "9.5k sqft-1 acre": "9.5k to 1 Acre",
    "10 acre-20 acre": "10 to 20 Acres",
    "20 acre-40 acre": "20 to 40 Acres",
    "40 acre-100 acre": "40 to 100 Acres",
};

const GEO_SCALES = ["County", "ZIP"];

export { TIME_SCALES, ACRE_RANGES, GEO_SCALES };
