import React, { useState, useEffect } from "react";

import Swal from "sweetalert2";

import { AGGrid } from "components";
import {
    post,
    isErrorResponse,
    parseErrorResponse,
    detectHeaderMapping,
} from "functions";

export default function Headers({
    scrubRequest,
    setScrubRequest,
    headers,
    setHeaders,
    exampleItems,
    setExampleItems,
    next,
    prev,
}) {
    const { input_file } = scrubRequest;

    useEffect(() => {
        const fetchHeaders = async () => {
            try {
                const data = await post("/analytics/get_headers_from_file/", {
                    input_file,
                });
                const { headers, example_items } = data;

                const detectedHeaders = detectHeaderMapping(data.headers);
                const headerMapping = {
                    apn: headers[detectedHeaders.apn],
                    first_name: headers[detectedHeaders.first_name],
                    last_name: headers[detectedHeaders.last_name],
                    address: headers[detectedHeaders.address],
                    zip: headers[detectedHeaders.zip],
                    lat: headers[detectedHeaders.lat],
                    lng: headers[detectedHeaders.lng],
                    size: headers[detectedHeaders.size],
                };

                setHeaders(headers);
                setScrubRequest(headerMapping);
                setExampleItems(example_items);
            } catch (xhr) {
                await Swal.fire({
                    title: "Error",
                    icon: "error",
                    text: parseErrorResponse(xhr),
                });
                prev();
            }
        };

        if (!headers) {
            fetchHeaders();
        }
    }, [input_file, headers, setHeaders, setExampleItems, setScrubRequest, prev]);

    if (!headers) {
        return <h5>Analyzing File...</h5>;
    }

    const submit = async () => {
        // Show header mapping confirmation modal
        const result = await Swal.fire({
            icon: "warning",
            title: "Triple Check",
            text: "Please triple check you've correctly mapped your columns. You will not be refunded for incorrectly mapping columns.",
            showCancelButton: true,
            confirmButtonText: "I Mapped The Columns Correctly",
        });
        if (result.isConfirmed) {
            next();
        }
    };

    return (
        <>
            <div className="mb-5">
                <div className="mb-3">
                    <h5>Define Mapping</h5>
                </div>
                <HeaderSelect
                    options={headers}
                    label="APN Column"
                    value={scrubRequest.apn}
                    onChange={(apn) => setScrubRequest({ apn })}
                />
                <HeaderSelect
                    options={headers}
                    label="Owner First Name"
                    value={scrubRequest.first_name}
                    onChange={(first_name) => setScrubRequest({ first_name })}
                />
                <HeaderSelect
                    options={headers}
                    label="Owner Last Name"
                    value={scrubRequest.last_name}
                    onChange={(last_name) => setScrubRequest({ last_name })}
                />
                <HeaderSelect
                    options={headers}
                    label="Owner Mailing ZIP Code"
                    value={scrubRequest.zip}
                    onChange={(zip) => setScrubRequest({ zip })}
                />
                <HeaderSelect
                    options={headers}
                    label="Property Latitude"
                    value={scrubRequest.lat}
                    onChange={(lat) => setScrubRequest({ lat })}
                />
                <HeaderSelect
                    options={headers}
                    label="Property Longitude"
                    value={scrubRequest.lng}
                    onChange={(lng) => setScrubRequest({ lng })}
                />
                <HeaderSelect
                    options={headers}
                    label="Property Acreage"
                    value={scrubRequest.size}
                    onChange={(size) => setScrubRequest({ size })}
                />
            </div>
            <div className="mb-5">
                <button className="btn bg-gradient-dark" onClick={prev}>
                    Previous
                </button>
                <button className="btn bg-gradient-dark float-end" onClick={submit}>
                    Next
                </button>
            </div>
            <ExampleItems items={exampleItems} scrubRequest={scrubRequest} />
        </>
    );
}

function HeaderSelect({ options, label, value, onChange }) {
    return (
        <div className="form-group multiselect">
            <label className="h6">{label}</label>
            <div>
                <select
                    className="form-control"
                    onChange={(e) => onChange(e.target.value)}
                    value={value}
                >
                    <option value=""></option>
                    {options.map((value) => (
                        <option key={value} value={value}>
                            {value}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
}

function ExampleItems({ items, scrubRequest }) {
    let columns = [
        { headerName: "APN", field: "apn" },
        { headerName: "First Name", field: "first_name" },
        { headerName: "Last Name", field: "last_name" },
        { headerName: "Latitude", field: "lat" },
        { headerName: "Longitude", field: "lng" },
        { headerName: "Acreage", field: "size" },
    ];

    const rows = [];
    for (let i in items) {
        const row = items[i];
        rows.push({
            id: i,
            apn: row[scrubRequest.apn],
            first_name: row[scrubRequest.first_name],
            last_name: row[scrubRequest.last_name],
            lat: row[scrubRequest.lat],
            lng: row[scrubRequest.lng],
            size: row[scrubRequest.size],
        });
    }

    return (
        <div>
            <h5>Preview Mapping</h5>
            <AGGrid fill_table={true} height="auto" rows={rows} columns={columns} />
        </div>
    );
}
