import React, { Component } from "react";
import { Link } from "react-router-dom";

import { Form, TextInput, Card, Button } from "library";
import { ajax_wrapper, save_token } from "functions";
import Swal from "sweetalert2";
import { withNavigate } from "hooks";

// GOOGLE OAUTH USES THIS DOCUMENTATION!!!!
// https://developers.google.com/identity/oauth2/web/guides/use-token-model
const GOOGLE_CLIENT_ID =
    "297720701797-4r7ijvdjgu5e7tf23jto6g4dqm8k23cs.apps.googleusercontent.com";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            client: null,
            error: "",
            data: {},

            waiting_for_response: false,
        };

        this.login = this.login.bind(this);
        this.google_login = this.google_login.bind(this);
        this.google_error = this.google_error.bind(this);
        this.login_callback = this.login_callback.bind(this);
    }

    componentDidMount() {
        let client = window.google.accounts.oauth2.initTokenClient({
            client_id: GOOGLE_CLIENT_ID,
            scope: "email profile openid",
            callback: (response) => this.google_login(response),
            error_callback: (response) => this.google_error(response),
        });

        this.setState({ client: client });
    }

    login(state) {
        let error = "";
        // Validate for before submitting
        if (!state["email"]) {
            error = "Email field is missing";
        } else if (!state["password"]) {
            error = "Password field is missing";
        }

        if (error) {
            this.fire_swal_error(error);
            return;
        }

        // Anti-mash check
        if (this.state.waiting_for_response) {
            return;
        }

        // Set the state before triggering request to prevent duplication
        this.setState(
            { waiting_for_response: true },
            function () {
                ajax_wrapper(
                    "POST",
                    "/user/token/",
                    {
                        email: state["email"],
                        password: state["password"],
                    },
                    this.login_callback,
                );
            }.bind(this),
        );
    }

    google_login(state) {
        if (state && state.access_token) {
            ajax_wrapper("POST", "/user/google_login/", state, this.login_callback);
        }
    }

    google_error(state) {
        console.log("Google Error", state);
    }

    login_callback(data) {
        // Sample errors:
        // {"error":"Unauthorized","code":401,"data":{"message":"No active account found with the given credentials","code":"no_active_account"}}
        // {"error":"Unauthorized","code":401,"data":{"detail":"Token is invalid or expired","code":"token_not_valid"}}
        // {"error":"Bad Request","code":400,"data":{"error":"All Seats Taken"}}
        // {"error":"Internal Server Error","code":500,"data":null}
        if (data.error) {
            const error =
                (data.data &&
                    (data.data.message || data.data.error || data.data.detail)) ||
                data.error;
            this.fire_swal_error(error);
            return;
        }
        save_token(data);
        this.props.navigate("/home");
    }

    fire_swal_error(text) {
        Swal.fire({
            title: "Warning",
            text: text,
            icon: "error",
            confirmButtonText: "OK",
        }).then(() => this.setState({ waiting_for_response: false }));
    }

    render() {
        let google_button = null;
        if (this.state.client) {
            google_button = (
                <Button
                    onClick={() => this.state.client.requestAccessToken()}
                    style={{ width: "100%" }}
                >
                    <div
                        style={{
                            marginRight: "10px",
                            display: "inline-block",
                            lineHeight: "20px",
                        }}
                    >
                        {"Login with Google"}
                    </div>
                    <img
                        style={{
                            width: "20px",
                            display: "inline-block",
                            verticalAlign: "top",
                        }}
                        src="https://lh3.googleusercontent.com/COxitqgJr1sJnIDe8-jiKhxDx1FrYbtRHKJ9z_hELisAlapwE9LUPh6fcXIfb5vwpbMl4xl9H9TRFPc5NOO8Sb3VSgIBrfRYvW6cUA"
                        alt=""
                    />
                </Button>
            );
        }

        return (
            <div className="col-xl-4 col-lg-5 col-md-7 mx-auto">
                <Card>
                    <div className="card-header text-center pt-4">
                        <h5>Sign in</h5>
                    </div>
                    <div className="card-body text-end">
                        <Form
                            submit={this.login}
                            submit_text="Login"
                            submit_button_type="gradient-info"
                            submit_button_class={"w-100 my-4 mb-4"}
                            waiting_for_external_response={
                                this.state.waiting_for_response
                            }
                        >
                            <TextInput key="email" name="email" placeholder="Email" />
                            <TextInput
                                key="password"
                                type="password"
                                name="password"
                                placeholder="Password"
                            />
                            <Link className="btn btn-link m-0 p-0" to="/reset-password">
                                Forgot password?
                            </Link>
                        </Form>
                        {google_button}
                    </div>
                </Card>
            </div>
        );
    }
}

export default withNavigate(Login);
