import React from "react";
import { createRoot } from "react-dom/client";
import { toast } from "react-toastify";

import { ToolTipBox } from "components";
import { Alert } from "library";

import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";

// Mapbox Plugins
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import RulerControl from "@mapbox-controls/ruler";
import "@mapbox-controls/ruler/src/index.css";
import { trim } from "jquery";

import { API_URL, MAPRIGHT_TOKEN } from "settings";
import { calculate_color_ranges, simple_gradient } from "functions";
import { MAPRIGHT_STYLES } from "constants";

const EMPTY_BORDER_COLOR = "rgb(33, 212, 253)";

const MAPRIGHT_SOURCES = [
    "wetlands",
    "floodplain_1",
    "floodplain_2",
    "contour_lines_1",
    "contour_lines_2",
    "contour_lines_3",
    "contour_lines_4",
    "contour_lines_5",
    "contour_lines_6",
    "contour_lines_7",
    "contour_lines_8",
    "contour_lines_9",
    "contour_lines_10",
    "contour_lines_11",
    "contour_lines_12",
    "contour_lines_13",
    "contour_lines_14",
];

// https://docs.mapbox.com/help/tutorials/use-mapbox-gl-js-with-react/
// https://github.com/mapbox/mapbox-react-examples/tree/master/data-overlay

class Legend extends React.PureComponent {
    render() {
        let gradient = `linear-gradient(to right, ${simple_gradient()})`;

        return (
            <div className="custom-map-legend">
                <div style={{ position: "relative", top: "-2px" }}>
                    <b
                        className="text-start"
                        style={{
                            fontSize: "10px",
                            fontWeight: "700",
                            float: "left",
                            paddingRight: "5px",
                            background: "white",
                            borderBottomRightRadius: "4px",
                        }}
                    >
                        cold
                    </b>

                    <b
                        className="text-end"
                        style={{
                            fontSize: "10px",
                            fontWeight: "700",
                            float: "right",
                            paddingLeft: "5px",
                            background: "white",
                            borderBottomLeftRadius: "4px",
                        }}
                    >
                        hot
                    </b>
                </div>
                <div
                    style={{
                        backgroundImage: gradient,
                        height: "30px",
                        borderRadius: "4px",
                    }}
                ></div>
            </div>
        );
    }
}

const COUNTY_LAYER = ["all", ["==", "level", 2], ["==", "iso_a2", "US"]];
const ZIP_LAYER = ["all", ["==", "level", 1], ["==", "iso_a2", "US"]];

const COUNTY_MIN_ZOOM = 3.5;
const ZIP_MIN_ZOOM = 6;

export default class MapboxMap extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            lng: -96.507,
            lat: 38.0255,
            zoom: 3.5,
            map_object: null,

            current_source: null,

            current_layers: [],
            layer_filters: COUNTY_LAYER,
            geocoder: null,
            geocode_result: null, // State variable to hold geocoding result (coordinates)
            is_data_layer_visible: true,
            wetlands_visible: false,
            flood_visible: false,
            contour_visible: false,
        };

        this.map_container = React.createRef();
        this.tooltip_container = null;

        this.toggle_data_visibility = this.toggle_data_visibility.bind(this);

        this.add_map_source = this.add_map_source.bind(this);
        this.set_geo_scale = this.set_geo_scale.bind(this);
        this.add_county_layers = this.add_county_layers.bind(this);
        this.add_zip_layers = this.add_zip_layers.bind(this);

        this.clear_layers = this.clear_layers.bind(this);
        this.clear_invisible_layers = this.clear_invisible_layers.bind(this);

        this.check_source_loaded_on_map = this.check_source_loaded_on_map.bind(this);
        this.add_heatmap_data_to_source = this.add_heatmap_data_to_source.bind(this);
        this.update_layer_colors = this.update_layer_colors.bind(this);

        this.handle_map_load = this.handle_map_load.bind(this);
        this.trigger_geocode = this.trigger_geocode.bind(this);
    }

    initMap() {
        const { lng, lat, zoom } = this.state;
        try {
            return new mapboxgl.Map({
                projection: "mercator",
                container: this.map_container.current,
                style: "mapbox://styles/kmlandinsights/clyvxfp7m005001px4kv01z02",
                center: [lng, lat],
                zoom: zoom,
                minZoom: 3.5,
                maxZoom: 20,
            });
        } catch (ex) {
            console.error(ex);
            toast.error(ex.message);
        }
        return null;
    }

    componentDidMount() {
        const map = this.initMap();
        if (!map) {
            return;
        }

        const { lng, lat, zoom } = this.state;

        map.on("load", this.handle_map_load);

        map.on("move", () => {
            this.setState({
                lng: map.getCenter().lng.toFixed(4),
                lat: map.getCenter().lat.toFixed(4),
                zoom: map.getZoom().toFixed(2),
            });
        });

        this.tooltip_container = document.createElement("div");
        const tooltip = new mapboxgl.Marker(this.tooltip_container)
            .setLngLat([0, 0])
            .addTo(map);

        map.on("mousemove", (e) => {
            const features = map.queryRenderedFeatures(e.point);
            tooltip.setLngLat(e.lngLat);
            map.getCanvas().style.cursor = features.length ? "pointer" : "";
            this.set_tooltip(features);
        });

        map.on("moveend", this.check_source_loaded_on_map);

        const tooltip_root = createRoot(this.tooltip_container);

        this.setState({
            map_object: map,
            tooltip_root: tooltip_root,
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.data_timestamp != prevProps.data_timestamp) {
            this.check_source_loaded_on_map();
        } else if (this.props.geo_scale != prevProps.geo_scale) {
            this.set_geo_scale();
        }

        if (this.props.geocoding_input != prevProps.geocoding_input) {
            this.trigger_geocode();
        }
        if (this.props.location_input != prevProps.location_input) {
            this.trigger_pan_to_location(this.props.location_input);
        }
    }

    trigger_geocode() {
        this.state.geocoder.query(this.props.geocoding_input);
    }

    trigger_pan_to_location(position) {
        const map = this.state.map_object;

        // Move map to the geocoded location
        if (position.bbox) {
            map.fitBounds(position.bbox);
        } else {
            map.flyTo({ center: position.center, zoom: 12 });
        }
    }

    set_geo_scale() {
        this.clear_invisible_layers();

        if (this.props.geo_scale == "County") {
            this.setState(
                {
                    current_source: "administrative",
                    layer_filters: COUNTY_LAYER,
                    is_data_layer_visible: true,
                },
                this.add_county_layers,
            );
        } else if (this.props.geo_scale == "ZIP") {
            this.setState(
                {
                    current_source: "postal",
                    layer_filters: ZIP_LAYER,
                    is_data_layer_visible: true,
                },
                this.add_zip_layers,
            );
        }
    }

    add_mapright_sources() {
        let map = this.state.map_object;

        for (let name of MAPRIGHT_SOURCES) {
            var layers = MAPRIGHT_STYLES[name]["layers"];

            map.addSource(name, MAPRIGHT_STYLES[name]["sources"]["composite"]);

            for (let layer of layers) {
                if (layer.id == "background") {
                    continue;
                }

                if (name.includes("floodplain") && layer.id.includes("500")) {
                    continue;
                }

                if (layer.source == "composite") {
                    layer.source = name;
                }

                map.addLayer(layer);

                let visible = "visible";
                if (name.includes("contour")) {
                    visible = this.state.contour_visible ? "visible" : "none";
                }
                map.setLayoutProperty(layer.id, "visibility", visible);
            }
        }
    }

    toggle_layer(layer) {
        let map = this.state.map_object;
        let flag_name = `${layer}_visible`;
        let layer_flag = this.state[flag_name];
        layer_flag = !layer_flag;

        if (!map) {
            return false;
        }

        for (let name of MAPRIGHT_SOURCES) {
            if (!name.includes(layer)) {
                continue;
            }

            var layers = MAPRIGHT_STYLES[name]["layers"];

            for (let layer of layers) {
                if (!map.getLayer(layer["id"])) {
                    continue;
                }

                if (layer_flag) {
                    map.setLayoutProperty(layer["id"], "visibility", "visible");
                } else {
                    map.setLayoutProperty(layer["id"], "visibility", "none");
                }
            }
        }

        let new_state = {};
        new_state[flag_name] = layer_flag;
        this.setState(new_state);
    }

    handle_map_load() {
        const map = this.state.map_object;

        // Add geocoder control to the map
        const geocoder = new MapboxGeocoder({
            accessToken: MAPRIGHT_TOKEN,
            mapboxgl: mapboxgl,
            marker: false, // Disable default marker
            placeholder: "Enter location...", // Placeholder text for the input field
        });

        map.addControl(geocoder);

        this.add_mapright_sources();

        map.loadImage("/static/images/mapright/LightBlue.png", (err, image) => {
            map.addImage("wetlands27", image);
        });
        map.loadImage("/static/images/mapright/DarkBlue.png", (err, image) => {
            map.addImage("500Flood", image);
        });
        map.loadImage("/static/images/mapright/DarkBlue.png", (err, image) => {
            map.addImage("100Flood", image);
        });
        map.loadImage("/static/images/mapright/DarkBlue.png", (err, image) => {
            map.addImage("Floodway7", image);
        });

        // Listen for 'result' event when a location is selected
        geocoder.on("result", (e) => {
            const coordinates = e.result.center;
            this.setState({ geocode_result: coordinates });

            this.trigger_pan_to_location(e.result);
        });

        map.addControl(new mapboxgl.FullscreenControl(), "bottom-right");

        // Add ruler control to the map
        map.addControl(
            new RulerControl({
                units: "feet", // Set units to feet
                labelFormat: (n) => `${n.toFixed(2)} ft`, // Customize label format to show feet
            }),
            "bottom-right",
        );

        this.setState(
            {
                loaded: true,
                geocoder: geocoder,
            },
            this.add_map_source,
        );
    }

    toggle_data_visibility() {
        let { map_object, is_data_layer_visible } = this.state;
        is_data_layer_visible = !is_data_layer_visible;

        if (map_object) {
            let layer_ids = [
                "county_fill",
                "county_borders",
                "zip_fill",
                "zip_borders",
            ];

            for (let id of layer_ids) {
                if (is_data_layer_visible) {
                    map_object.setLayoutProperty(id, "visibility", "visible");
                } else {
                    map_object.setLayoutProperty(id, "visibility", "none");
                }
            }

            let hidden_layers = [
                "empty_county_borders",
                "empty_zip_borders",
                "empty_county_fill",
                "empty_zip_fill",
            ];
            for (let id of hidden_layers) {
                if (is_data_layer_visible) {
                    map_object.setLayoutProperty(id, "visibility", "none");
                } else {
                    let is_visible = "none";
                    if (this.props.geo_scale == "County" && id.includes("county")) {
                        is_visible = "visible";
                    }
                    if (this.props.geo_scale == "ZIP" && id.includes("zip")) {
                        is_visible = "visible";
                    }
                    map_object.setLayoutProperty(id, "visibility", is_visible);
                }
            }

            this.setState({ is_data_layer_visible: is_data_layer_visible });
        }
    }

    set_tooltip(features) {
        if (features.length && features[0].layer.id.includes("fill")) {
            this.state.tooltip_root.render(
                React.createElement(ToolTipBox, {
                    features,
                }),
            );
        } else {
            this.state.tooltip_root.render(null);
        }
    }

    add_map_source() {
        let map = this.state.map_object;

        let source = map.getSource("maptiler_source");
        if (source) {
            return false;
        }

        map.addSource("maptiler_source", {
            type: "vector",
            tiles: [`${API_URL}/tiles/countries/{z}/{x}/{y}.pbf`],
        });

        map.addSource("mapbox-dem", {
            type: "raster-dem",
            url: "mapbox://mapbox.mapbox-terrain-dem-v1",
            tileSize: 512,
            maxzoom: 18,
        });

        // add the DEM source as a terrain layer
        map.setTerrain({ source: "mapbox-dem", exaggeration: 1.0 });

        this.set_geo_scale();
    }

    clear_layers() {
        let map = this.state.map_object;

        if (map.getLayer("county_borders")) {
            map.removeLayer("county_borders");
        }
        if (map.getLayer("county_fill")) {
            map.removeLayer("county_fill");
        }
        if (map.getLayer("zip_borders")) {
            map.removeLayer("zip_borders");
        }
        if (map.getLayer("zip_fill")) {
            map.removeLayer("zip_fill");
        }

        this.clear_invisible_layers();
    }

    clear_invisible_layers() {
        let map = this.state.map_object;

        if (map.getLayer("empty_county_borders")) {
            map.removeLayer("empty_county_borders");
        }
        if (map.getLayer("empty_zip_borders")) {
            map.removeLayer("empty_zip_borders");
        }
        if (map.getLayer("empty_county_fill")) {
            map.removeLayer("empty_county_fill");
        }
        if (map.getLayer("empty_zip_fill")) {
            map.removeLayer("empty_zip_fill");
        }
    }

    add_county_layers() {
        this.clear_layers();

        let map = this.state.map_object;

        // add maptiler layer
        map.addLayer({
            id: "county_borders",
            type: "line",
            source: "maptiler_source",
            "source-layer": "administrative", // Replace with the correct source layer name
            filter: this.state.layer_filters,
            paint: {
                "line-width": 0.5, // Set the width of the border
            },
        });

        map.addLayer({
            id: "county_fill",
            type: "fill",
            source: "maptiler_source",
            "source-layer": "administrative", // Replace with the correct source layer name
            filter: this.state.layer_filters,
            paint: {
                "fill-opacity": 0.5,
            },
        });

        var base_width = 0.5;
        let base_zoom = 5;
        map.addLayer({
            id: "empty_county_borders",
            type: "line",
            source: "maptiler_source",
            "source-layer": "administrative", // Replace with the correct source layer name
            filter: this.state.layer_filters,
            paint: {
                "line-width": {
                    type: "exponential",
                    base: 2,
                    stops: [
                        [3.5, base_width * Math.pow(2, 3.5 - base_zoom)],
                        [10, base_width * Math.pow(2, 10 - base_zoom)],
                    ],
                },
                "line-color": EMPTY_BORDER_COLOR,
            },
            layout: {
                // Make the layer visible by default.
                visibility: "none",
            },
        });

        map.addLayer({
            id: "empty_county_fill",
            type: "fill",
            source: "maptiler_source",
            "source-layer": "administrative", // Replace with the correct source layer name
            filter: this.state.layer_filters,
            layout: {
                // Make the layer visible by default.
                visibility: "none",
            },
            paint: {
                "fill-opacity": 0,
            },
        });

        this.check_source_loaded_on_map();
    }

    add_zip_layers() {
        this.clear_layers();

        let map = this.state.map_object;

        map.addLayer({
            id: "zip_borders",
            type: "line",
            source: "maptiler_source",
            "source-layer": "postal",
            filter: this.state.layer_filters,
            paint: {
                "line-width": 0.5, // Set the width of the border
            },
        });

        map.addLayer({
            id: "zip_fill",
            type: "fill",
            source: "maptiler_source",
            "source-layer": "postal", // Replace with the correct source layer name
            filter: this.state.layer_filters,
            paint: {
                "fill-opacity": 0.5,
            },
        });

        var base_width = 0.5;
        let base_zoom = 8;
        map.addLayer({
            id: "empty_zip_borders",
            type: "line",
            source: "maptiler_source",
            "source-layer": "postal",
            filter: this.state.layer_filters,
            paint: {
                "line-width": {
                    type: "exponential",
                    base: 2,
                    stops: [
                        [6, base_width * Math.pow(2, 6 - base_zoom)],
                        [13, base_width * Math.pow(2, 13 - base_zoom)],
                    ],
                },
                "line-color": EMPTY_BORDER_COLOR,
            },
            layout: {
                // Make the layer visible by default.
                visibility: "none",
            },
        });

        map.addLayer({
            id: "empty_zip_fill",
            type: "fill",
            source: "maptiler_source",
            "source-layer": "postal", // Replace with the correct source layer name
            filter: this.state.layer_filters,
            layout: {
                // Make the layer visible by default.
                visibility: "none",
            },
            paint: {
                "fill-opacity": 0,
            },
        });

        this.check_source_loaded_on_map();
    }

    check_source_loaded_on_map(e) {
        let map = this.state.map_object;
        if (!map) {
            return;
        }

        if (map.getSource("maptiler_source") && map.isSourceLoaded("maptiler_source")) {
            this.add_heatmap_data_to_source();
        } else {
            setTimeout(this.check_source_loaded_on_map, 200);
        }
    }

    update_layer_colors() {
        let map = this.state.map_object;

        let invert_colors = this.props.invert_colors;
        let layer_color_interpolation = calculate_color_ranges(
            this.props.map_color_data,
            invert_colors,
        );

        //County Layers
        if (map.getLayer("county_borders")) {
            map.setPaintProperty("county_borders", "line-color", [
                "case",
                // If value is 0, fill with grey
                ["==", ["feature-state", "value"], 0],
                "rgba(0, 0, 0, 1.0)",
                // If value is not 0, apply the existing color interpolation
                ["!=", ["feature-state", "value"], 0],
                layer_color_interpolation,
                // Default color if none of the above conditions are met
                "rgba(100, 100, 100, .2)",
            ]);
        }
        if (map.getLayer("county_fill")) {
            map.setPaintProperty("county_fill", "fill-color", [
                "case",
                // If value is 0, fill with grey
                ["==", ["feature-state", "value"], 0],
                "rgba(0, 0, 0, 1.0)",
                // If value is not 0, apply the existing color interpolation
                ["!=", ["feature-state", "value"], 0],
                layer_color_interpolation,
                // Default color if none of the above conditions are met
                "rgba(100, 100, 100, .2)",
            ]);
        }

        //ZIP Layers
        if (map.getLayer("zip_borders")) {
            map.setPaintProperty("zip_borders", "line-color", [
                "case",
                // If value is 0, fill with grey
                ["==", ["feature-state", "value"], 0],
                "rgba(0, 0, 0, 1.0)",
                // If value is not 0, apply the existing color interpolation
                ["!=", ["feature-state", "value"], 0],
                layer_color_interpolation,
                // Default color if none of the above conditions are met
                "rgba(100, 100, 100, .2)",
            ]);
        }
        if (map.getLayer("zip_fill")) {
            map.setPaintProperty("zip_fill", "fill-color", [
                "case",
                // If value is 0, fill with grey
                ["==", ["feature-state", "value"], 0],
                "rgba(0, 0, 0, 1.0)",
                // If value is not 0, apply the existing color interpolation
                ["!=", ["feature-state", "value"], 0],
                layer_color_interpolation,
                // Default color if none of the above conditions are met
                "rgba(100, 100, 100, .2)",
            ]);
        }
    }

    // Join the data to coresponding features
    add_heatmap_data_to_source(e) {
        this.update_layer_colors();

        let map = this.state.map_object;

        var tiles = map.querySourceFeatures("maptiler_source", {
            sourceLayer: this.state.current_source,
            filter: this.state.layer_filters,
        });

        // Clear feature state for entire layer
        map.removeFeatureState({
            source: "maptiler_source",
            sourceLayer: this.state.current_source,
        });

        tiles.forEach((row) => {
            if (!row.id || !(row.id in this.props.map_color_data)) {
                return false;
            }

            let value = this.props.map_color_data[row.id];

            map.setFeatureState(
                {
                    source: "maptiler_source",
                    sourceLayer: this.state.current_source,
                    id: row.id,
                },
                {
                    value: parseFloat(value),
                    data_order: this.props.map_lookup_order,
                    data_points: this.props.map_lookup_data[row.id],
                },
            );
        });
    }

    render() {
        let container_style = Object.assign(
            { position: "relative" },
            this.props.container_style,
        );

        let zoom_warning = null;
        let alert_style = {
            background: "white",
            borderRadius: "4px",
            padding: "2px 0px",

            position: "absolute",
            zIndex: "30",
            bottom: "0px",
            left: "0px",
            right: "0px",
            width: "275px",
            margin: "0px auto 30px",
            textAlign: "center",
            fontWeight: "bold",
        };

        if (this.props.geo_scale == "County" && this.state.zoom < COUNTY_MIN_ZOOM) {
            zoom_warning = (
                <div style={alert_style}>Please zoom in to view metrics</div>
            );
        } else if (this.props.geo_scale == "ZIP" && this.state.zoom < ZIP_MIN_ZOOM) {
            zoom_warning = (
                <div style={alert_style}>Please zoom in to view metrics</div>
            );
        }

        return (
            <div style={container_style}>
                {/*<div className="map-data-sidebar">
                    Longitude: {this.state.lng} | Latitude: {this.state.lat} | Zoom: {this.state.zoom}
                </div> */}

                <div className="custom-map-controls">
                    <button
                        onClick={() => this.toggle_layer("wetlands")}
                        className="btn btn-outline-secondary btn-icon-only custom-map-visiblity ms-sm-1"
                        style={{ fontSize: "16px" }}
                        title={
                            this.state.wetlands_visible
                                ? "Disable Wetlands"
                                : "Enable Wetlands"
                        }
                    >
                        <img className="noun-icon" src="/static/images/wetland.svg" />
                        {this.state.wetlands_visible ? null : (
                            <div className="strike-through-inline" />
                        )}
                    </button>
                    <button
                        onClick={() => this.toggle_layer("flood")}
                        className="btn btn-outline-secondary btn-icon-only custom-map-visiblity ms-sm-1"
                        style={{ fontSize: "16px" }}
                        title={
                            this.state.flood_visible ? "Disable Flood" : "Enable Flood"
                        }
                    >
                        <img className="noun-icon" src="/static/images/flood.svg" />
                        {this.state.flood_visible ? null : (
                            <div className="strike-through-inline" />
                        )}
                    </button>
                    <button
                        onClick={() => this.toggle_layer("contour")}
                        className="btn btn-outline-secondary btn-icon-only custom-map-visiblity ms-sm-1"
                        style={{ fontSize: "16px" }}
                        title={
                            this.state.contour_visible
                                ? "Disable Contour"
                                : "Enable Contour"
                        }
                    >
                        <img className="noun-icon" src="/static/images/contour.svg" />
                        {this.state.contour_visible ? null : (
                            <div className="strike-through-inline" />
                        )}
                    </button>
                    <button
                        onClick={this.toggle_data_visibility}
                        className="btn btn-outline-secondary btn-icon-only custom-map-visiblity ms-sm-1"
                        style={{ fontSize: "16px", color: "black" }}
                        title={
                            this.state.is_data_layer_visible
                                ? "Disable Heatmap"
                                : "Enable Heatmap"
                        }
                    >
                        {!this.state.is_data_layer_visible ? (
                            <i className="fas fa-eye-slash"></i>
                        ) : (
                            <i className="fas fa-eye"></i>
                        )}
                    </button>
                    <Legend />
                </div>

                {zoom_warning}

                {/* Mapbox map */}
                <div
                    ref={this.map_container}
                    className="map-container"
                    style={{ height: "575px" }} // Set the height here
                />
            </div>
        );
    }
}
