import React, { useState, useEffect, useRef, useCallback } from "react";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Alert from "react-bootstrap/Alert";

import { fetch, pluralize, formatNumber } from "functions";

import MapIconButton from "./map_button";
import ReactMapControl from "./map_control";
import LITypeahead from "./typeahead";

const SECTION_PARCEL = "parcel";
const SECTION_OWNERSHIP = "ownership";
const SECTION_STRUCTURE = "structure";
const SECTION_OTHER = "other";

export function FilterControl({ open, setOpen }) {
    return (
        <ReactMapControl position="top-right">
            <MapIconButton
                icon="fa-solid fa-filter"
                title="Filter"
                onClick={() => setOpen(!open)}
            />
        </ReactMapControl>
    );
}

export function FilterPanel({ setOpen, searchResult, setSearchResult }) {
    const containerRef = useRef();
    const [activeSection, setActiveSection] = useState();

    // Form fields
    const [county, setCounty] = useState();

    // Form state
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    // Parcel API response
    const { results, count, next, previous } = searchResult || {};

    const onCountySelected = ({ id }) => {
        setCounty(id);
    };

    const searchParcel = async () => {
        setLoading(true);
        try {
            const result = await fetch(
                `/api/property/parcels/?county=${county}&page_size=5`,
            );
            console.log("Filter result", result);
            setSearchResult(result);
        } catch (xhr) {
            console.log(xhr);
        }
        setLoading(false);
    };

    const validate = () => {
        if (!county) {
            setError("County field is required");
            return false;
        }
        return true;
    };

    const applyFilters = () => {
        reset();
        if (validate()) {
            searchParcel();
        }
    };

    const reset = () => {
        setError();
        setSearchResult();
    };

    const scrollToSection = (sectionId) => {
        const container = containerRef.current;
        const targetSection = container.querySelector(`[data-section="${sectionId}"]`);
        if (targetSection) {
            container.scrollTo({
                top: targetSection.offsetTop - container.offsetTop,
                behavior: "smooth",
            });
        }
    };

    return (
        <div id="filter-control" className="d-flex flex-column">
            <div id="filter-control-main" className="d-flex flex-fill">
                <div id="filter-control-nav" className="d-flex flex-column">
                    <NavButton
                        name="Parcel"
                        icon="fa-map-marker"
                        selected={activeSection === SECTION_PARCEL}
                        onClick={() => scrollToSection(SECTION_PARCEL)}
                    />
                    <NavButton
                        name="Ownership"
                        icon="fa-user-group"
                        selected={activeSection === SECTION_OWNERSHIP}
                        onClick={() => scrollToSection(SECTION_OWNERSHIP)}
                    />
                    <NavButton
                        name="Structure"
                        value="structure"
                        icon="fa-house"
                        selected={activeSection === SECTION_STRUCTURE}
                        onClick={() => scrollToSection(SECTION_STRUCTURE)}
                    />
                    <NavButton
                        name="Other"
                        value="other"
                        icon="fa-plus"
                        selected={activeSection === SECTION_OTHER}
                        onClick={() => scrollToSection(SECTION_OTHER)}
                    />
                </div>
                <div
                    id="filter-control-content"
                    className="d-flex flex-column flex-fill"
                >
                    <div id="filter-control-header" className="d-flex py-3">
                        <h4 className="flex-fill mb-0">Filter Parcels</h4>
                        <TitleBarButton icon="fa-ellipsis" />
                        <TitleBarButton
                            icon="fa-circle-xmark"
                            onClick={() => setOpen(false)}
                        />
                    </div>
                    <ScrollSpy
                        containerRef={containerRef}
                        onActiveSectionChange={setActiveSection}
                    />
                    <div
                        ref={containerRef}
                        id="filter-control-body"
                        className="flex-fill pe-3"
                    >
                        <SectionHeader section={SECTION_PARCEL}>Parcel</SectionHeader>
                        {!!error && <Alert variant="danger">{error}</Alert>}
                        <div className="form-group">
                            <Label htmlFor="countyInput" tooltip="County help text.">
                                County
                            </Label>
                            <div className="input-group">
                                <span className="input-group-text">
                                    <i className="fas fa-search" />
                                </span>
                                <CountyFIPSSearch
                                    id="countyInput"
                                    onCountySelected={onCountySelected}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <Label
                                htmlFor="subdivisionSelect"
                                tooltip="Subdivision help text."
                            >
                                Subdivision
                            </Label>
                            <select className="form-control" id="subdivisionSelect">
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </select>
                        </div>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            Pellentesque consectetur cursus posuere. Integer mi tortor,
                            fermentum ac felis a, porta dignissim magna. Nulla lobortis
                            luctus ante, non tempus felis. Nullam eget fermentum mi, in
                            malesuada neque. Nunc tincidunt, purus efficitur malesuada
                            porta, magna dui rutrum est, eu consequat tortor libero et
                            odio. Etiam luctus ipsum id ligula convallis, et
                            pellentesque risus tempor. Phasellus nec viverra neque. Nunc
                            lorem lorem, rutrum sed magna eu, volutpat pharetra lorem.
                        </p>
                        <SectionHeader section={SECTION_OWNERSHIP}>
                            Ownership
                        </SectionHeader>
                        <p>
                            Ut quis consectetur augue. Vestibulum euismod, orci molestie
                            dapibus pretium, lectus justo pellentesque leo, et interdum
                            odio felis eget tellus. Praesent et nunc metus. Donec
                            efficitur rhoncus dolor, non blandit arcu pretium bibendum.
                            Maecenas rutrum turpis in felis rutrum condimentum.
                            Phasellus in diam eget elit tristique bibendum. Sed molestie
                            sem ac orci gravida, eu iaculis nunc auctor. Vivamus gravida
                            nulla id elit tempor auctor. Suspendisse posuere quis diam
                            sed vehicula. Phasellus nec condimentum nunc, id faucibus
                            nibh.
                        </p>
                        <SectionHeader section={SECTION_STRUCTURE}>
                            Structure
                        </SectionHeader>
                        <p>
                            Pellentesque tempor arcu molestie risus venenatis placerat
                            vitae ultricies erat. Phasellus non tellus lorem. Morbi a
                            aliquet turpis, a tempus nunc. Duis pulvinar dignissim
                            vulputate. Nunc in turpis arcu. Maecenas a elit arcu.
                            Vivamus lobortis elit eget tortor interdum aliquet. Mauris
                            et mauris viverra, ornare lectus vel, dignissim lorem. Duis
                            at erat eu urna eleifend pellentesque vel et metus. Nullam
                            eu molestie nisi.
                        </p>
                        <SectionHeader section={SECTION_OTHER}>Other</SectionHeader>
                        <p>
                            Nunc odio augue, semper non interdum id, sollicitudin in
                            nibh. Quisque rhoncus porttitor massa sit amet venenatis.
                            Cras non egestas turpis. Duis non diam purus. In rutrum
                            aliquet arcu. Vivamus nec massa et ex porttitor aliquam at
                            ac turpis. Mauris ac sodales diam, non finibus magna. Sed
                            sit amet laoreet nibh. Vestibulum vitae leo sed sapien
                            sollicitudin lacinia eu luctus tortor.
                        </p>
                        <p>
                            In vitae sodales ante, vel condimentum odio. Nulla facilisi.
                            Phasellus ac dui tortor. Aliquam a ligula blandit, laoreet
                            neque vitae, convallis elit. Proin id augue non dolor
                            ullamcorper cursus. Vivamus commodo ornare nunc eget
                            posuere. Nulla pellentesque massa vel enim pulvinar, eu
                            posuere ex viverra. Vivamus at metus nunc. Nullam luctus
                            sapien id ex venenatis porta. Sed vel congue turpis. Fusce
                            suscipit pharetra urna ut ornare. Sed ultrices imperdiet
                            neque, id porttitor lorem tempus non. Integer vitae finibus
                            eros. Vivamus et leo nec nisi vehicula placerat.
                        </p>
                    </div>
                </div>
            </div>
            <div id="filter-control-footer" className="border-top">
                <div
                    id="filter-control-status"
                    className="bg-gradient-primary text-center"
                >
                    <h5 className="my-3">
                        {count > 0
                            ? `${formatNumber(count)} ${pluralize("Parcel", count)} Found`
                            : count === 0
                              ? "No Parcels Found"
                              : ""}
                    </h5>
                </div>
                <div
                    id="filter-control-actions"
                    className="d-flex flex-wrap justify-content-evenly"
                >
                    <button
                        className="btn btn-sm bg-gradient-secondary w-45"
                        onClick={reset}
                    >
                        Clear Filters
                    </button>
                    <button
                        className="btn btn-sm bg-gradient-dark w-45"
                        onClick={applyFilters}
                    >
                        Apply Filters
                    </button>
                    <button className="btn btn-sm bg-gradient-info w-45">
                        Save List
                    </button>
                    <button className="btn btn-sm bg-gradient-primary w-45">
                        Export List
                    </button>
                </div>
            </div>
        </div>
    );
}

function NavButton({ name, icon, selected, onClick }) {
    const className = `btn ${selected ? "btn-primary" : "btn-outline-secondary"} mb-0 px-0 border-0`;
    return (
        <button className={className} onClick={onClick}>
            <i className={`fas ${icon}`} />
            <div className="text-xxs">{name}</div>
        </button>
    );
}

function TitleBarButton({ icon, onClick }) {
    return (
        <button
            className="btn btn-lg btn-outline-secondary border-0 py-1 px-2 mb-0"
            onClick={onClick}
        >
            <i className={`fas ${icon}`} />
        </button>
    );
}

function SectionHeader({ section, children }) {
    return (
        <>
            <h5 data-section={section} className="mb-1">
                {children}
            </h5>
            <hr className="horizontal dark mt-0" />
        </>
    );
}

function Label({ htmlFor, tooltip, children }) {
    return (
        <label htmlFor={htmlFor}>
            {children}{" "}
            {tooltip && (
                <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>{tooltip}</Tooltip>}
                >
                    <i className="fas fa-circle-question text-secondary" />
                </OverlayTrigger>
            )}
        </label>
    );
}

function ScrollSpy({ id, className, onActiveSectionChange, containerRef }) {
    const [activeSection, setActiveSection] = useState();

    const handleScroll = useCallback(() => {
        const container = containerRef.current;
        if (!container) {
            return;
        }

        const childrenArray = Array.from(container.querySelectorAll("[data-section]"));
        const scrollTop = container.scrollTop;

        let closestSection = null;
        let closestDifference = Infinity;

        for (let i = 0; i < childrenArray.length; i++) {
            const child = childrenArray[i];
            const offsetTop = child.offsetTop;
            const difference = Math.abs(offsetTop - scrollTop);

            if (difference < closestDifference) {
                closestDifference = difference;
                closestSection = child;
            }
        }

        if (closestSection) {
            const newActiveSection = closestSection.getAttribute("data-section");
            if (newActiveSection !== activeSection) {
                setActiveSection(newActiveSection);
                onActiveSectionChange(newActiveSection);
            }
        }
    }, [containerRef, activeSection, setActiveSection, onActiveSectionChange]);

    useEffect(() => {
        if (!containerRef.current) {
            return;
        }

        const container = containerRef.current;
        container.addEventListener("scroll", handleScroll);
        handleScroll(); // Initial active section determination

        return () => {
            container.removeEventListener("scroll", handleScroll);
        };
    }, [containerRef, handleScroll]);

    return null;
}

function CountyFIPSSearch({ id, onCountySelected }) {
    const [options, setOptions] = useState();
    const [loading, setLoading] = useState(false);

    const searchCounty = async (query) => {
        setLoading(true);
        try {
            const result = await fetch(`/api/property/fips_lookup/?name=${query}`);
            setOptions(result?.results);
        } catch (xhr) {
            console.log(xhr);
        }
        setLoading(false);
    };

    const onSearchResultClicked = (selection) => {
        const option = selection[0];
        if (!option) {
            return;
        }
        onCountySelected(option);
    };

    return (
        <LITypeahead
            placeholder="Enter County Name"
            isLoading={loading}
            options={options}
            onSearch={searchCounty}
            onChange={onSearchResultClicked}
            inputProps={{ id }}
        />
    );
}
