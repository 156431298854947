import React, { useState } from "react";

export default function MapIconButton({ icon, title, enableTooltip, onClick }) {
    const [showTooltip, setShowTooltip] = useState(false);

    // Default to true if undefined
    enableTooltip = enableTooltip === undefined ? true : !!enableTooltip;

    return (
        <>
            <MapIconButtonTooltip
                title={title}
                visible={enableTooltip && showTooltip}
            />
            <button
                className="text-sm text-bold text-dark text-gradient"
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
                onMouseUp={() => onClick && onClick()}
            >
                <i className={icon} />
            </button>
        </>
    );
}

function MapIconButtonTooltip({ title, visible }) {
    if (!visible) {
        return;
    }
    return (
        <div className="mapboxgl-ctrl-custom-tooltip border-radius-2xl bg-gradient-dark text-white text-bold border border-2 border-white">
            {title}
        </div>
    );
}
