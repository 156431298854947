import React from "react";

import MapIconButton from "./map_button";
import ReactMapControl from "./map_control";

export default function ToolsControl() {
    return (
        <ReactMapControl position="top-right">
            <MapIconButton icon="fa-solid fa-gear" title="Tools" />
        </ReactMapControl>
    );
}
