import React from "react";

import { format_metric_value } from "functions";

export default class ToolTipBox extends React.Component {
    render() {
        const { features } = this.props;
        let feature = features[0];
        let data = feature.state["data_points"];
        let data_order = feature.state["data_order"];

        let content = null;

        if (data) {
            let data_point_rows = [];
            for (let key of data_order) {
                let value = format_metric_value(key, data[key]);

                if (["name", "state", "type", "county_name"].includes(key)) {
                    continue;
                }

                data_point_rows.push(
                    <tr key={key}>
                        <td>{`${key}: `}</td>
                        <td style={{ textAlign: "right" }}>{value}</td>
                    </tr>,
                );
            }

            let region_name = `${data["name"]}, ${data["state"]}`;
            if (data["type"] == "zip") {
                region_name = `${data["name"]}, ${data["county_name"]}, ${data["state"]}`;
            }

            content = (
                <div style={{ position: "absolute", top: "0px", left: "0px" }}>
                    <div
                        style={{
                            background: "white",
                            whiteSpace: "nowrap",
                            margin: ".5rem",
                            padding: "1rem",
                            borderRadius: "4px",
                        }}
                    >
                        <div style={{ fontSize: "14px", paddingBottom: "5px" }}>
                            <b>{region_name}</b>
                        </div>
                        <table style={{ width: "100%" }}>
                            <tbody>{data_point_rows}</tbody>
                        </table>
                    </div>
                </div>
            );
        }

        return content;
    }
}
