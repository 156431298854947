import React, { useContext, useState, useEffect } from "react";

import { UserContext } from "context";
import { CREDIT_TYPE_LAND_SCRUBBING, CREDIT_TYPE_SKIP_TRACING } from "constants";
import { toast } from "react-toastify";
import {
    formatCredits,
    formatDate,
    formatMoneyCents,
    parseErrorResponse,
    post,
    prettify_string,
} from "functions";
import { AGGrid, PurchaseCreditsModal } from "components";
import Swal from "sweetalert2";

export default function Settings() {
    return (
        <div className="row">
            <div className="col-lg-9 mx-auto">
                <Wallet />
                <Subscription />
                <BasicInfo />
                <ChangePassword />
            </div>
        </div>
    );
}

function BasicInfo() {
    const { user, setUser } = useContext(UserContext);
    const [firstName, setFirstName] = useState(user.first_name);
    const [lastName, setLastName] = useState(user.last_name);
    const [email, setEmail] = useState(user.email);

    const updateProfile = async () => {
        if (firstName === user.first_name && lastName === user.last_name) {
            return;
        }
        try {
            const user = await post("/user/user/", {
                first_name: firstName,
                last_name: lastName,
            });
            setUser(user);
            toast.success("Profile saved");
        } catch (xhr) {
            toast.error(parseErrorResponse(xhr));
        }
    };

    const updateEmail = async () => {
        if (email === user.email) {
            return;
        }
        try {
            const user = await post("/user/user/change_email/", {
                new_email: email,
            });
            setUser(user);
            toast.success("Email updated");
        } catch (xhr) {
            toast.error(parseErrorResponse(xhr));
        }
    };

    const save = () => {
        updateProfile();
        updateEmail();
    };

    return (
        <div className="card mt-4">
            <div className="card-header">
                <h5>Basic Info</h5>
            </div>
            <div className="card-body pt-0">
                <div className="row">
                    <div className="col-6">
                        <label className="form-label" htmlFor="firstName">
                            First Name
                        </label>
                        <div className="input-group">
                            <input
                                id="firstName"
                                name="firstName"
                                className="form-control"
                                type="text"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <label className="form-label" htmlFor="lastName">
                            Last Name
                        </label>
                        <div className="input-group">
                            <input
                                id="lastName"
                                name="lastName"
                                className="form-control"
                                type="text"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <label className="form-label mt-4" htmlFor="email">
                            Email
                        </label>
                        <div className="input-group">
                            <input
                                id="email"
                                name="email"
                                className="form-control"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <button
                    className="btn bg-gradient-dark btn-sm float-end mt-4 mb-0"
                    onClick={save}
                >
                    Save
                </button>
            </div>
        </div>
    );
}

function ChangePassword() {
    const { user } = useContext(UserContext);
    const [oldPassword, setOldPassword] = useState();
    const [newPassword, setNewPassword] = useState();
    const [confirmNewPassword, setConfirmNewPassword] = useState();

    const changePassword = async () => {
        try {
            await post("/user/user/change_password/", {
                old_password: oldPassword,
                new_password: newPassword,
                confirm_new_password: confirmNewPassword,
            });
            toast.success("Password changed");
        } catch (xhr) {
            toast.error(parseErrorResponse(xhr));
        }
    };

    return (
        <div className="card mt-4">
            <div className="card-header">
                <h5>Change Password</h5>
            </div>
            <div className="card-body pt-0">
                <label className="form-label" htmlFor="password">
                    Current password
                </label>
                <div className="form-group">
                    <input
                        id="password"
                        name="password"
                        className="form-control"
                        type="password"
                        onChange={(e) => setOldPassword(e.target.value)}
                    />
                </div>
                <label className="form-label" htmlFor="newPassword">
                    New password
                </label>
                <div className="form-group">
                    <input
                        id="newPassword"
                        name="newPassword"
                        className="form-control"
                        type="password"
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                </div>
                <label className="form-label" htmlFor="confirmPassword">
                    Confirm new password
                </label>
                <div className="form-group">
                    <input
                        id="confirmPassword"
                        name="confirmPassword"
                        className="form-control"
                        type="password"
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                    />
                </div>
                <button
                    className="btn bg-gradient-dark btn-sm float-end mt-4 mb-0"
                    onClick={changePassword}
                >
                    Update password
                </button>
            </div>
        </div>
    );
}

function Wallet() {
    const { transactions } = useContext(UserContext);
    const [showModal, setShowModal] = useState(false);

    const columns = [
        {
            headerName: "Created",
            field: "created_at",
            valueFormatter: ({ value }) => formatDate(value),
            filter: true,
            cellDataType: "dateString",
        },
        {
            headerName: "Credit",
            field: "credit",
            valueFormatter: ({ value }) => (value && formatCredits(value)) || "",
        },
        {
            headerName: "Debit",
            field: "debit",
            valueFormatter: ({ value }) => (value && formatCredits(value)) || "",
        },
        {
            headerName: "Paid",
            field: "paid_amount",
            valueFormatter: ({ value }) =>
                value != null ? formatMoneyCents(value) : "",
        },
        { headerName: "Description", field: "description" },
    ];

    return (
        <div className="card">
            <div className="card-header pb-0">
                <h5>Credits</h5>
            </div>
            <div className="card-body">
                {(showModal && (
                    <PurchaseCreditsModal
                        onConfirmed={() => setShowModal(false)}
                        onDismissed={() => setShowModal(false)}
                    />
                )) ||
                    null}
                {transactions.length === 0 ? (
                    <div className="text-center">
                        <button
                            className="btn btn-lg btn-primary"
                            onClick={() => setShowModal(true)}
                        >
                            Purchase Credits
                        </button>
                    </div>
                ) : (
                    <AGGrid fill_table={true} rows={transactions} columns={columns} />
                )}
            </div>
        </div>
    );
}

function Subscription() {
    const { user } = useContext(UserContext);
    const [portalURL, setPortalURL] = useState();

    const calendlyUrl =
        "https://calendly.com/hello-6kuo/land-insights-exit-call-with-ari-owens";

    const openSubscriptionOptions = () => {
        return Swal.fire({
            title: "Subscription Options",
            text: "What would you like to do?",
            icon: "question",
            showConfirmButton: true,
            showDenyButton: true,
            confirmButtonText: "Update Card",
            denyButtonText: "Cancel Account",
        }).then((result) => {
            if (result.isConfirmed) {
                window.open(portalURL, "_blank").focus();
            } else if (result.isDenied) {
                window.open(calendlyUrl, "_blank").focus();
            }
        });
    };

    // Fetch Stripe Billing Portal Session URL on load
    useEffect(() => {
        const fetchPortalURL = async () => {
            try {
                const session = await post("/user/stripe_portal_session/");
                setPortalURL(session.url);
            } catch (xhr) {
                toast.error(parseErrorResponse(xhr));
            }
        };
        fetchPortalURL();
    }, []);

    return (
        <div className="card mt-4">
            <div className="card-header">
                <h5>Manage Subscription</h5>
            </div>
            <div className="card-body text-center">
                <a
                    className={`btn btn-secondary btn-lg ${!portalURL ? "disabled" : ""}`}
                    onClick={openSubscriptionOptions}
                >
                    Open My Subscription Portal
                </a>
            </div>
        </div>
    );
}
