export { TIME_SCALES, ACRE_RANGES, GEO_SCALES } from "./basics.js";

export { MAPRIGHT_STYLES } from "./mapright_styles.js";

// Error codes
export const INSUFFICIENT_FUNDS = "insufficient_funds";
export const USER_EXISTS = "user_exists";
export const DUPLICATE_LAND_SCRUB = "duplicate_land_scrub";

// Land Scrubbing prices (must match home/settings.py)
export const LAND_LOCKED_COST = 2;
export const WETLANDS_COST = 4;
export const FLOOD_ZONE_COST = 4;

// LandScrubbingRequest Status
export const LAND_SCRUB_COMPLETED = "Completed";
export const LAND_SCRUB_FAILURE = "Failure";
export const LAND_SCRUB_WORKING = "Working";

// LandScrubbingRow.Status
export const LAND_SCRUBBING_ROW_FAILURE = "failure";

// SkipTrace status field
export const SKIP_TRACE_SUCCESS = "success";
export const SKIP_TRACE_FAILURE = "failure";

// WalletTransaction.CreditType
export const CREDIT_TYPE_CASH = "cash";
export const CREDIT_TYPE_GIFT = "gift";
