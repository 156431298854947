import React, { useEffect, useState } from "react";

import Swal from "sweetalert2";

import { fetch, parseErrorResponse } from "functions";

export default function ParcelDetail({ parcelID, onClose }) {
    const [parcel, setParcel] = useState();

    useEffect(() => {
        const fetchParcel = async (id) => {
            try {
                const parcel = await fetch(`/api/property/parcels/${id}/`);
                setParcel(parcel);
                console.log("Parcel detail", parcel);
            } catch (xhr) {
                const errorMessage =
                    xhr.status === 404
                        ? `No data available for parcel ${id}`
                        : parseErrorResponse(xhr);
                Swal.fire({
                    title: "Error",
                    text: errorMessage,
                    icon: "error",
                    confirmButtonText: "OK",
                });
            }
        };

        fetchParcel(parcelID);
    }, [parcelID]);

    return (
        <div id="parcel-detail" className="d-flex flex-column">
            <div className="d-flex p-3 pe-0 pb-0">
                <h4 className="flex-fill">Parcel Detail</h4>
                <TitleBarButton icon="fa-circle-xmark" onClick={onClose} />
            </div>
            <div className="flex-fill overflow-auto" style={{ marginBottom: 10 }}>
                <div className="p-3">
                    {!parcel ? (
                        "Loading..."
                    ) : (
                        <table>
                            <thead>
                                <tr>
                                    <th>Key</th>
                                    <th>Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(parcel).map(([key, value]) => (
                                    <tr key={key}>
                                        <td>{key}</td>
                                        <td>{value}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </div>
    );
}

function TitleBarButton({ icon, onClick }) {
    return (
        <button
            className="btn btn-lg btn-outline-secondary border-0 py-1 px-2"
            onClick={onClick}
        >
            <i className={`fas ${icon}`} />
        </button>
    );
}
