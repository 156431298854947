import React from "react";

import ReactMapControl from "./map_control";

export default function LogoControl({ resetViewState }) {
    return (
        <ReactMapControl id="logo-control" position="top-left">
            <button onClick={resetViewState}>
                <img
                    src="/static/images/icon-128.png"
                    alt="LandInsights"
                    style={{ width: 30, marginBottom: 2 }}
                />
            </button>
        </ReactMapControl>
    );
}
