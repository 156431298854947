import React, { Component } from "react";

import { clear_token } from "functions";
import { Card } from "library";
import { withNavigate } from "hooks";
import { clearCurrentToken } from "functions";

class Logout extends Component {
    componentDidMount() {
        clearCurrentToken();

        // Refresh page instead of using react-router because some parts of the
        // app read the token value on page load.
        window.location.href = "/";
    }

    render() {
        return (
            <Card>
                <div style={{ textAlign: "center" }}>
                    <h5>Logging out...</h5>
                </div>
            </Card>
        );
    }
}

export default withNavigate(Logout);
