import React from "react";

import { useControl } from "react-map-gl";

import { default as MapboxInspectControl } from "@mapbox-controls/inspect";
import "@mapbox-controls/inspect/src/index.css";

// InspectControl wraps the Mapbox inspect plugin to make it React compatible.
// See: https://visgl.github.io/react-map-gl/docs/api-reference/use-control
export default function InspectControl(props) {
    useControl(() => new MapboxInspectControl(props), {
        position: props.position,
    });
}
