import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Map, {
    Layer,
    Marker,
    NavigationControl,
    ScaleControl,
    Source,
    useControl,
} from "react-map-gl";

import { MAPBOX_TOKEN, TILER_URL, API_URL } from "settings";

import DataControl from "./controls/data";
import DrawControl from "./controls/draw";
import HelpControl from "./controls/help";
import InspectControl from "./controls/inspect";
import LayerControl from "./controls/layer";
import ListsControl from "./controls/lists";
import LogoControl from "./controls/logo";
import MapContext from "./context";
import ParcelDetail from "./controls/parcel_detail";
import ToolsControl from "./controls/tools";
import { FilterControl, FilterPanel } from "./controls/filter";
import { SearchControl, SearchBox } from "./controls/search";

// Mapbox filters
const COUNTY_FILTER = [
    "all",
    ["==", ["get", "level"], 2],
    ["==", ["get", "iso_a2"], "US"],
];
const ZIP_FILTER = [
    "all",
    ["==", ["get", "level"], 1],
    ["==", ["get", "iso_a2"], "US"],
];

const initialViewState = {
    longitude: -80.49238,
    latitude: 34.30861,
    zoom: 14,
};

const maptilerSource = {
    id: "maptiler_source",
    type: "vector",
    tiles: [`${API_URL}/tiles/countries/{z}/{x}/{y}.pbf`],
};

const parcelSource = {
    id: "tiler_source",
    type: "vector",
    tiles: [`${TILER_URL}/maps/parcels/{z}/{x}/{y}.pbf`],
    promoteId: "id",
};

// Identify county layer
const countyIdentify = {
    id: "county_identify",
    type: "fill",
    "source-layer": "administrative",
    paint: { "fill-color": "#000000", "fill-opacity": 0 },
    filter: COUNTY_FILTER,
};

const countyOutline = {
    id: "county_outline",
    type: "line",
    "source-layer": "administrative",
    paint: {
        "line-width": 3,
        "line-color": "#ff00f2",
    },
    filter: COUNTY_FILTER,
};

// Highlight county shadow
const countyShadow = {
    id: "county_shadow",
    type: "fill",
    "source-layer": "administrative",
    paint: { "fill-color": "#000000", "fill-opacity": 0.5 },
};

// Identify zip layer
const zipIdentify = {
    id: "zip_identify",
    type: "fill",
    "source-layer": "postal",
    paint: { "fill-color": "#000000", "fill-opacity": 0 },
    filter: ZIP_FILTER,
};

const zipOutline = {
    id: "zip_outline",
    type: "line",
    "source-layer": "postal",
    paint: {
        "line-width": 3,
        "line-color": "#ff00f2",
    },
    filter: ZIP_FILTER,
};

// Highlight zip shadow
const zipShadow = {
    id: "zip_shadow",
    type: "fill",
    "source-layer": "postal",
    paint: { "fill-color": "#000000", "fill-opacity": 0.5 },
};

// Identify parcel layer
const parcelIdentify = {
    id: "parcel_identify",
    type: "fill",
    "source-layer": "parcels",
    paint: { "fill-color": "#000000", "fill-opacity": 0 },
};

// Highlight parcel shadow
const parcelShadow = {
    id: "parcel_shadow",
    type: "fill",
    "source-layer": "parcels",
    paint: { "fill-color": "#000000", "fill-opacity": 0.5 },
};

// Parcel outline layer
const parcelOutline = {
    id: "parcel_line",
    type: "line",
    "source-layer": "parcels",
    paint: {
        "line-width": 1,
        "line-color": "#ffa500",
    },
};

// Hover parcel layer
const parcelOutlineHover = {
    id: "parcel_line_hover",
    type: "line",
    "source-layer": "parcels",
    paint: {
        "line-width": 4,
        "line-color": "#fbcf33",
    },
};

// Active parcel layer
const parcelOutlineActive = {
    id: "parcel_line_active",
    type: "line",
    "source-layer": "parcels",
    paint: {
        "line-width": 4,
        "line-color": "#17c1e8",
    },
};

export default function ParcelViewer() {
    const mapRef = useRef();
    const location = useLocation();
    const navigate = useNavigate();
    const [viewState, setViewState] = useState(() => parseViewState(location));

    const [hoverParcelID, setHoverParcelID] = useState();
    const [activeParcelID, setActiveParcelID] = useState();

    const [showFilterPanel, setShowFilterPanel] = useState(true);
    const [showSearchBox, setShowSearchBox] = useState(true);
    const [searchResult, setSearchResult] = useState();

    // Render no features
    const emptyFilter = ["==", "code", ""];

    const [mapFilter, setMapFilter] = useState();

    const resetViewState = useCallback(() => {
        setViewState(initialViewState);
    }, [setViewState]);

    const updateHash = useCallback(
        (newViewState) => {
            const hash = `${newViewState.zoom.toFixed(2)}/${newViewState.longitude.toFixed(6)}/${newViewState.latitude.toFixed(6)}`;
            navigate(`#${hash}`, { replace: true });
        },
        [navigate],
    );

    const debouncedUpdateHash = useMemo(() => debounce(updateHash, 500), [updateHash]);

    const onMouseMove = (e) => {
        const feature = e.features && e.features[0];
        const id = feature?.id;
        if (id !== hoverParcelID) {
            setHoverParcelID(id);
        }
    };

    const onClick = (e) => {
        const feature = e.features && e.features[0];
        const id = feature?.id;
        console.log("Clicked feature", feature);

        // Toggle active feature
        setActiveParcelID((oldValue) => (id === oldValue ? null : id));

        // Clear map filter if active parcel changed
        if (id !== activeParcelID) {
            setMapFilter();
        }
    };

    const hoverFilter = useMemo(
        () => ["==", "id", hoverParcelID || ""],
        [hoverParcelID],
    );

    const hoverActive = useMemo(
        () => ["==", "id", activeParcelID || ""],
        [activeParcelID],
    );

    // Set body class name on mount
    useEffect(() => {
        document.body.className = "parcel-viewer";
        return () => {
            // Clear class name on dismount
            document.body.className = "";
        };
    }, []);

    const context = { setMapFilter };

    // Apply map filter
    let zipFilter = emptyFilter;
    let countyFilter = emptyFilter;
    let parcelFilter = emptyFilter;
    let identifyFilter = emptyFilter;
    if (mapFilter) {
        const { identifyLayer, filter, inverseFilter } = mapFilter;
        identifyFilter = filter;
        if (identifyLayer === "county_identify") {
            countyFilter = inverseFilter;
        } else if (identifyLayer === "zip_identify") {
            zipFilter = inverseFilter;
        } else if (identifyLayer === "parcel_identify") {
            parcelFilter = inverseFilter;
        }
    }

    return (
        <MapContext.Provider value={context}>
            <Map
                ref={mapRef}
                mapboxAccessToken={MAPBOX_TOKEN}
                width="100%"
                height="100%"
                mapStyle="mapbox://styles/kmlandinsights/clyvxfp7m005001px4kv01z02"
                minZoom={1}
                maxZoom={20}
                onMove={(e) => {
                    setViewState(e.viewState);
                    debouncedUpdateHash(e.viewState);
                }}
                onMouseMove={onMouseMove}
                onClick={onClick}
                interactiveLayerIds={["parcel_identify"]}
                {...viewState}
            >
                <Source {...parcelSource}>
                    <Layer {...parcelIdentify} />
                    <Layer {...parcelShadow} filter={parcelFilter} />
                    <Layer {...parcelOutline} />
                    <Layer {...parcelOutlineHover} filter={hoverFilter} />
                    <Layer {...parcelOutlineActive} filter={hoverActive} />
                </Source>
                <Source {...maptilerSource}>
                    <Layer {...countyIdentify} />
                    <Layer {...countyOutline} filter={identifyFilter} />
                    <Layer {...countyShadow} filter={countyFilter} />
                    <Layer {...zipIdentify} />
                    <Layer {...zipOutline} filter={identifyFilter} />
                    <Layer {...zipShadow} filter={zipFilter} />
                </Source>
                <LogoControl resetViewState={resetViewState} />
                <SearchControl open={showSearchBox} setOpen={setShowSearchBox} />
                <FilterControl open={showFilterPanel} setOpen={setShowFilterPanel} />
                <DataControl />
                <LayerControl />
                <ToolsControl />
                <ListsControl />
                <HelpControl />
                <NavigationControl position="top-right" />
                <InspectControl position="bottom-right" />
                <ScaleControl unit="imperial" />
                <DrawControl position={"bottom-right"} />
                {showSearchBox && <SearchBox setActiveParcelID={setActiveParcelID} />}
                {showFilterPanel && !activeParcelID && (
                    <FilterPanel
                        setOpen={setShowFilterPanel}
                        searchResult={searchResult}
                        setSearchResult={setSearchResult}
                    />
                )}
                {activeParcelID && (
                    <ParcelDetail
                        parcelID={activeParcelID}
                        onClose={() => setActiveParcelID()}
                    />
                )}
                {searchResult?.results.map((parcel) => (
                    <Marker
                        key={parcel.id}
                        longitude={parcel.point[0]}
                        latitude={parcel.point[1]}
                    />
                ))}
            </Map>
        </MapContext.Provider>
    );
}

function parseViewState(location) {
    const hash = location.hash.slice(1);
    const [zoom, lng, lat] = hash.split("/").map(parseFloat);

    if (!isNaN(zoom) && !isNaN(lat) && !isNaN(lng)) {
        return {
            longitude: lng,
            latitude: lat,
            zoom,
        };
    }

    return initialViewState;
}

function debounce(func, delay) {
    let timer;
    return (...args) => {
        const context = this;
        clearTimeout(timer);
        timer = setTimeout(() => func.apply(context, args), delay);
    };
}
