import React from "react";

import SimpleFileUpload from "react-simple-file-upload";

import { SIMPLE_FILE_UPLOAD_KEY } from "settings";

export default function UploadCSV({
    setScrubRequest,
    setHeaders,
    setExampleItems,
    next,
}) {
    const onSuccess = (input_file) => {
        // Reset headers and example items from prior upload if applicable
        setHeaders(null);
        setExampleItems(null);

        setScrubRequest({ input_file });
        next();
    };
    return (
        <div className="form">
            <div className="form-group">
                <label>CSV File</label>
                <div className="text-center">
                    <SimpleFileUpload
                        apiKey={SIMPLE_FILE_UPLOAD_KEY}
                        onSuccess={onSuccess}
                        preview="false"
                        width="600"
                        height="300"
                        accepted=".csv"
                    />
                </div>
            </div>
        </div>
    );
}
